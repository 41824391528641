import { provideApolloClient, useQuery } from "@vue/apollo-composable";
import { cloneDeep } from "@apollo/client/utilities";
import { UserSettings, UserSetting } from "./types";
import { ApolloClient } from '@/utils/ApolloClient';
import { createSetting } from "./create-setting";
import { updateSetting } from "./update-setting";
import { deleteSetting } from "./delete-setting";
import { computed, reactive, watch } from 'vue';
import { useUserReturn } from './../use-user';
import { gql } from "@apollo/client/core";

const GQL_GET_USER_SETTINGS = gql`
  query GQL_GET_USER_SETTINGS($personID: String!) {
    userSettings(where: { personID: { _eq: $personID } } ) {
      id
      name
      value
      updatedAt
    }
  }
`;

const GQL_SUB_USER_SETTINGS = gql`
  subscription GQL_SUB_USER_SETTINGS($personID: String!) {
    userSettings(where: { personID: { _eq: $personID } } ) {
      id
      name
      value
      updatedAt
    }
  }
`;

export function useUserSettings(user: useUserReturn) {

  const personID = computed(() => user.data.value?.personID);

  const { result, subscribeToMore, onResult, refetch } = provideApolloClient(ApolloClient)(() =>
    useQuery(
      GQL_GET_USER_SETTINGS,
      () => ({ personID: personID.value }),
      () => ({
        enabled: user.isReady.value,
        // if we disable cache we do not get subscription updates
        // fetchPolicy: 'cache-and-network',
        // nextFetchPolicy: 'cache-and-network'
      })
    )
  )

  subscribeToMore(() =>  ({
    document: GQL_SUB_USER_SETTINGS,
    variables: ({ personID: personID.value }),
  }));

  /**
   * We say the UserSettings are ready after our first result.
   * It is on the individual features to consume the
   * UserSettings and create defaults if necessary.
   */
  const hasInitSubscription = computed(() => !!result.value);

  /**
   * Consume all records and build an object
   * that groups records by their Setting
   * Name (which can be dynamic).
   */
  const data = reactive<Partial<UserSettings>>({});

  onResult((response) => {
    if (response.loading) return;
    const settings = cloneDeep(response?.data?.userSettings || []);
    Object.assign(data, settings.reduce((acc: UserSettings, record: UserSetting) => {
      Object.assign(acc, {
        [record.name]: {
          id: record.id,
          value: record.value,
          update: updateSetting(record.id),
          delete: deleteSetting(record.id)
        }
      })

      return acc;
    }, {
      create: createSetting(personID.value),
    }));
  });

  // assume User logged out, reset the Settings state
  watch(personID, (val, prevVal) => {
    if (!!prevVal && !val) {
      for (const key of Object.keys(data)) {
        delete data[key];
      }
    }
  })

  const isReady = computed(() => hasInitSubscription.value && !!data?.create);

  return {
    refetch,
    data,
    isReady
  }
}

export interface useUserSettingsReturn extends ReturnType<typeof useUserSettings> {}

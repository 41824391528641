/**
 * @function isString check if param is a string
 * @param {any} str param in question
 * @returns {boolean} true if string, false if not
 */
export const isString = (str: any): boolean => typeof str === 'string' || str instanceof String;

/**
 * @function slugify slugify a string
 * @param {string} str string to slugify
 * @returns {string} slufigied string
 */
export const slugify = (str: string): string => {
  return str
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export const toLowerCaseAndReplaceSpaces = (str: string): string => str.toLowerCase().replace(/\s+/g, '').trim();

/**
 * @function toTitleCase convert string to title case
 * @param {string} str title to convert
 * @returns {string} title case string
 */
export const toTitleCase = (str: string): string => {
  return str.replace(/\w\S*/g, (t) => {
    return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase();
  });
};

/**
 * @function randomHexString
 * generate a random hex string
 * @param {number} length string length
 * @returns {string} result hex string
 */
export const randomHexString = (len: number): string => {
  const n = (): number => Math.random() * 16;
  return [...Array(len)].map(() => Math.floor(n()).toString(16)).join('');
};

/**
 *
 * @param str
 * @param n
 * @param useWordBoundary
 * @returns
 */
export function truncateString(str: string | null | undefined, n: number, useWordBoundary = true) {
  if (!str) return '';
  if (str.length <= n) return str;

  const subString = str.substr(0, n - 1);

  return (useWordBoundary ? subString.substr(0, subString.lastIndexOf(' ')) : subString) + '...';
}

/**
 * https://stackoverflow.com/a/7225450/865803
 */
export const fromCamelCaseToSentenceCase = (str: string): string => {
  const result = str.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult.trim();
};

/**
 *
 */
export function toSentenceCase(str: string): string {
  const sentence = str.replace(/([A-Z])/g, ' $1').trim(); // replace and trim
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
}

/**
 *
 */
export function toPascalCase(str: string): string {
  if (!str) return '';

  // @ts-ignore - upgrade TS, thinks `str` may be null
  return str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
    .join('');
}

export function uppercaseFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// console.log(toCamelCase("Hello World")); // helloWorld
// console.log(toCamelCase("make-it_camelCase, please!")); // makeItCamelCasePlease
// console.log(toCamelCase("this - is...a  [email protected] #$%^&*()-=+_>?<,./;'\":123 test!")); // thisIsaEmailProtected123Test
// console.log(toCamelCase("script_place_id_fkey")); // scriptPlaceIdFkey
// console.log(toCamelCase("use-camera-search")); // useCameraSearch
export function toCamelCase(str: string): string {

  return str
    //
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) => {
      return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
    })
    // Remove non-alphanumeric characters except for spaces and underscores
    .replace(/[^a-zA-Z0-9 _]/g, "")
    // Convert to camel case
    .replace(/(?:^\w|[A-Z]|[\s_]+\w)/g, (letter, index) => {
      return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
    })
    // Remove non-leading spaces and underscores
    .replace(/[\s_]+/g, '');
}

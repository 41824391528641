import { qx_ePrivilege_enum } from "@/graphql/types";
import { ConfigRouteObject } from '@/types';

// DEV NOTE :: deprecated legacy route, will be removed in the future
export const LEGACY_SEARCH_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', 'magnifying-glass'],
  UI_LABEL: 'Legacy Search',
  ROUTE_PATH: 'legacy/:id?',
  ROUTE_NAME: 'search.legacy',
  ROUTE_PARAM: ':id',
  PRIVILEGES: [qx_ePrivilege_enum.Search],
};

export const SEARCH_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', 'magnifying-glass'],
  UI_LABEL: 'Search',
  ROUTE_PATH: 'search',
  ROUTE_NAME: 'search.index',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.Search],
};

export const SEARCH_MODULES_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', 'magnifying-glass'],
  UI_LABEL: 'Modules',
  ROUTE_PATH: 'module',
  ROUTE_NAME: 'search.module.index',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.Search],
};

export const SEARCH_MODULE_ANALYTICS_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'object-group'],
  UI_LABEL: 'Analytic Search',
  ROUTE_PATH: 'analytics',
  ROUTE_NAME: 'search.module.analytics',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.Search]
};

export const SEARCH_MODULE_EVENTS_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'magnifying-glass'],
  UI_LABEL: 'Event Search',
  ROUTE_PATH: 'events',
  ROUTE_NAME: SEARCH_ROUTE.ROUTE_NAME,
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.Search]
};

export const SEARCH_MODULE_LPR_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'rectangle-barcode'],
  UI_LABEL: 'License Plate Recognition',
  ROUTE_PATH: 'license-plate-recognition',
  ROUTE_NAME: 'search.module.lpr',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.Search]
};

export const SEARCH_MODULE_AUDIO_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'waveform-lines'],
  UI_LABEL: 'Audio Recognition',
  ROUTE_PATH: 'audio-recognition',
  ROUTE_NAME: 'search.module.audio',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.Search]
};

export const SEARCH_MODULES: ConfigRouteObject[] = [
  SEARCH_MODULE_EVENTS_ROUTE,
  SEARCH_MODULE_ANALYTICS_ROUTE,
  // SEARCH_MODULE_LPR_ROUTE,
  // SEARCH_MODULE_AUDIO_ROUTE,
  // LEGACY_SEARCH_ROUTE
];

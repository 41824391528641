import { isString, toCamelCase } from "@/functions/string";
import { QX_SUPPORT_STAFF } from "@/config/app";
import { isArray } from "@/functions/array";

type ProvidedError = Error | string | unknown;

export type ErrorHandlerResponse = {
  message: string;
  error: string;
  raw: ProvidedError;
}

export function ErrorHandler(
  error: ProvidedError | ProvidedError[],
  fallback?: string
): ErrorHandlerResponse {
  /**
   * If an array of errors is provided,
   * iterate over each error.
   */
  if (isArray(error)) {
    // @ts-ignore
    return error.reduce((acc, err) => {
      acc.push((ErrorHandler(err)))
      return acc;
    }, [])
  }

  /**
   * If a string is provided, assume
   * it is a custom error message.
   */
  if (isString(error)) {
    return {
      message: error as string,
      error: '',
      raw: ''
    };
  }

  /**
   * Handle FK constraints
   * =====================
   * Example:
   *  Message: Foreign key violation. update or delete on table "Schedule"
   *  violates foreign key constraint "Permission_schedule_fkey" on
   *  table "Permission", Path: undefined
   */
  // @ts-ignore
  const errorMessage = error?.message || '';
  if (errorMessage.includes('Foreign key violation')) {
    console.error('Foreign Key Violation', { error })
    // find all words in string that are wrapped in quotation marks
    const regex = /"(.*?)"/g
    // using example above... returns ["Schedule", "Permission_schedule_fkey", "Permission"]
    const words = errorMessage.match(regex).map(w => BackendToFrontendSchemaTranslator(w));
    // return last word in array; e.g., "Permission"
    return {
      message: `Unable to perform action. Please delete or remove all associated ${words.pop()} records.`,
      error: errorMessage,
      raw: error as ProvidedError as ProvidedError
    }

  // TODO :: "validation-failed"
  //   {
  //     "extensions": {
  //       "code": "validation-failed",
  //       "path": "$.selectionSet.updateVideoSource.args._set.gatewayPlaceID"
  //     },
  //     "message": "field 'gatewayPlaceID' not found in type: 'qx_VideoSource_set_input'"
  //   }
  } else if (errorMessage.includes('not found in type:')) {
    console.error('Validation Failed', { error })
    return {
      message: `Unable to perform action. This is due to insufficient permissions or a malformed request. Please contact ${QX_SUPPORT_STAFF}.`,
      error: errorMessage,
      raw: error as ProvidedError
    }

  /**
   * Camera Device Delete - Media Profile has video attached
   */
  } else if (errorMessage.includes('has video attached')) {
    console.error('Device delete failed, Media Profile has video attached', { error })
    return {
      message: `Unable to delete Device. A Media Profile has video attached.`,
      error: errorMessage,
      raw: error as ProvidedError
    }

  /**
   * "Not Authorized"
   */
  } else if (errorMessage.includes('Not Authorized')) {
    console.error('Not Authorized', { error })
    return {
      message: `Not Authorized`,
      error: errorMessage,
      raw: error as ProvidedError
    }

  /**
   * "http exception when calling webhook"
   */
  } else if (errorMessage.includes('http exception when calling webhook')) {
    console.error('Webhook Exception', { error })
    return {
      message: `A network error has occurred.`,
      error: errorMessage,
      raw: error as ProvidedError
    }

  /**
   * Uniqueness Violations
   *  - https://app.shortcut.com/qumulex/story/33763
   *  - https://app.shortcut.com/qumulex/story/16150
   */
  } else if (errorMessage.includes('Uniqueness violation')) {
    console.error('Uniqueness Violation', { error })
    return {
      message: `Unable to perform action. A similar record exists, perhaps at a higher scope than is visible.`,
      error: errorMessage,
      raw: error as ProvidedError
    }

  // DB Query Error
  } else if (errorMessage.includes('query')) {
    console.error('DB Query Error', { error });
    return {
      message: 'An error occurred when communicating with the server.',
      error: errorMessage,
      raw: error as ProvidedError
    }

  } else if (errorMessage.includes('permission has failed')) {
    //   {
    //     "extensions": {
    //       "code": "permission-error",
    //       "path": "$.selectionSet.createPlaces.args.objects"
    //     },
    //     "message": "check constraint of an insert/update permission has failed"
    //   }
    console.error('Permission Error', { error })
    return {
      message: `You do not have permission.`,
      error: errorMessage,
      raw: error as ProvidedError
    }

  } else if (errorMessage.includes('internal error')) {
    // {
    //   "message": "internal error",
    //   "extensions": {
    //     "path": "$",
    //     "code": "unexpected"
    //   }
    // }
    console.error('Internal Error', { error });
    return {
      message: `An error occurred when attempting to complete your request.`,
      error: errorMessage,
      raw: error as ProvidedError
    }
  }

  // TODO :: not-null constraint
  // {
  //   "extensions": {
  //     "code": "constraint-violation",
  //     "path": "$"
  //   },
  //   "message": "Not-NULL violation. null value in column \"customerPlaceID\" violates not-null constraint"
  // }

  // TODO :: remote-schema-error
  // {
  //   "message": "HTTP exception occurred while sending the request to \"http://qxreplication:9500/graphql\"",
  //   "extensions": {
  //     "path": "$",
  //     "code": "remote-schema-error"
  //   }
  // }

  /**
   * Handle Apollo Client errors that may bubble up.
   */
  else if (error?.name?.includes?.('ApolloError')) {
    console.error('Error Handled: ApolloError', { error })
    return {
      message: (errorMessage || fallback) || 'An error occurred when attempting to send a request to the server.',
      error: errorMessage,
      raw: error as ProvidedError
    }
  }

  /**
   * Return the error message, no special formatting needed.
   */
  else if (errorMessage) {
    console.error('Error Handled: return error message as-is', { error })
    return {
      message: errorMessage,
      error: errorMessage,
      raw: error as ProvidedError
    };
  }

  /**
   * If the errorMessage is empty, use the provided fallback.
   */
  else if (fallback) {
    console.error('Error Handled: Condition Fallback', { error })
    return {
      message: fallback,
      error: errorMessage,
      raw: error as ProvidedError
    };
  }


  // TODO :: not good enough... need to give more info
  // TODO :: sentry integration
  console.error('An unknown error ocurred.', { error })
  return {
    message: 'An unknown error ocurred.',
    error: '',
    raw: error as ProvidedError
  };
}

function BackendToFrontendSchemaTranslator(backendName: string) {
  const providedString = toCamelCase(backendName)
  switch(providedString) {
    case 'videoSource':
      return 'Camera'
    case 'flow':
      return 'Rule'
    default:
      return backendName;
  }
}

import { computed, onBeforeMount, ref, watch } from 'vue';
import { computedAsync } from '@vueuse/core';
import { GenericObject } from '@/types';
// DEV NOTE :: enums missing, having packge install issues
import { UAParser } from 'ua-parser-js';

// https://github.com/faisalman/ua-parser-js#uaparser
interface UAParserResult {
  getResult: () => {
    ua: string,
    browser: GenericObject,
    cpu: GenericObject,
    device: GenericObject,
    engine: GenericObject,
    os: GenericObject
  };
  getBrowser: () => {
    name: string,
    version: string,
    withClientHints: () => Promise<{
      name: string,
      version: string,
      [key: string]: unknown,
    }>
  };
  getDevice: () => { model: '', type: '', vendor: '' };
  getEngine: () => { name: '', version: '' };
  getOS: () => { name: '', version: '' };
  getCPU: () => { architecture: '' };
  getUA: () => string;
  // bug in package download that is not including types or all the methods
  withFeatureCheck?: () => unknown;
  withClientHints?: () => unknown;
}

export function useDeviceAgent() {
  const parser = ref<UAParserResult>(new UAParser(navigator.userAgent));

  // DEV NOTE :: note tested; attempt to prevent stale device information
  const _result = computed(() => {
    return parser.value.getResult()
  })

  const features = computed(() => {
    const feats = parser.value?.withFeatureCheck?.() || {}

    return {
      ...feats,
      touch: window.matchMedia("(pointer: coarse)").matches
    }
  });

  const browser = computedAsync(async () => {
    const clientHints = await parser.value?.getBrowser?.().withClientHints?.();
    return {
      ...(clientHints || {})
    }
  }, { name: '', version: '' });

  const device = computed(() => _result.value?.device);
  const engine = computed(() => _result.value?.engine);
  const os = computed(() => _result.value?.os);

  const mobile = computed(() => device.value?.is?.('mobile'))
  const tablet = computed(() => device.value?.is?.('tablet'))

  // iPad and iPhone
  const iOS = computed(() => !!os.value?.is?.('iOS'))
  const iPadOS = computed(() => !!(os.value?.is?.('macOS') && navigator.maxTouchPoints > 1)) // Safari reports iPad as Desktop MacOS, so use the maxTouchPoints - https://stackoverflow.com/questions/58344491/request-browser-ismobiledevice-not-working-with-ipadair2-and-ios-13-0-1/60553965#60553965
  const iPhone = computed(() => !!device.value?.is?.('iPhone'))

  onBeforeMount(() => {
    const htmlNode = document.querySelector('html');
    if (iPhone.value) {
      htmlNode?.classList.add('iphone');
    }
  });

  watch(browser, val => {
    const htmlNode = document.querySelector('html');
    if (val.name) {
      htmlNode?.classList.add(val.name.toLowerCase());
    }

    if (device.value?.vendor) {
      htmlNode?.classList.add(device.value.vendor.toLowerCase());
    }
  })

  return {
    features,
    browser,
    device,
    engine,
    os,
    mobile,
    tablet,
    iOS,
    iPadOS,
    iPhone
  }
}

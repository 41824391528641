export const faHairStyle = {
  prefix: 'fas',
  iconName: 'hair-style',
  icon: [
    '512',
    '512',
    [],
    'qxhairstyle',
    'M10.7441,284.354681 C21.4173488,259.83045 28.7161225,233.988751 32.4348961,207.532233 C46.1506181,110.278489 106.529306,101.284573 126.40551,101 C142.714009,121.910855 201.789282,176.278375 375.176877,176.264322 L375.282275,176.264322 C376.092079,176.280132 454.802898,172.997001 544.218584,262.396878 C544.773678,262.906299 599.980886,355.637025 563.432562,474.774794 C479.121371,482.36332 496.013871,295.40786 375.176877,331.980975 C289.55601,352.336268 240.321801,343.415334 136.918265,312.844309 C69.3715562,288.491165 98.9450812,475.769731 14.9740511,474.774794 C-9.13210327,398.2053 0.791067044,307.199579 10.7441,284.354681 Z'
  ]
}

export const faPants = {
  prefix: 'fas',
  iconName: 'pants',
  icon: [
    '512',
    '512',
    [],
    'qxpants',
    'M334.516407,0.561445343 L345.225441,0.559834623 C341.832537,11.0970971 340,22.3345425 340,34 C340,94.1990377 388.800962,143 449,143 C459.042228,143 468.767271,141.64197 478.001499,139.09954 L478,534.39542 L478,534.39542 C478,580.911268 440.363194,575.837257 393.777778,575.837257 C347.192361,575.837257 309.555556,580.911268 309.555556,534.39542 L309.555556,198.009623 L267.444444,198.009623 L267.444444,534.39542 C267.444444,580.911268 229.807639,575.837257 183.222222,575.837257 C136.636806,575.837257 99,580.911268 99,534.39542 L98.9996036,139.370014 C107.934677,141.738167 117.31998,143 127,143 C187.199038,143 236,94.1990377 236,34 C236,22.2957402 234.155251,11.0223445 230.74065,0.454708899 C264.277753,0.516775045 300.423362,0.557162773 334.516407,0.561445343 Z M478,0 L478.001135,116.919995 C469.133078,119.970986 459.616192,121.627451 449.712418,121.627451 C401.710571,121.627451 362.797386,82.7142654 362.797386,34.7124183 C362.797386,22.5848019 365.281272,11.0373283 369.768067,0.550976458 C423.781393,0.512367869 467.279785,0.354278617 478,0 Z M99,0 C109.282521,0.130553005 153.097763,0.293998116 207.590537,0.410882883 C212.121192,10.933564 214.627451,22.530307 214.627451,34.7124183 C214.627451,82.7142654 175.714265,121.627451 127.712418,121.627451 C117.650278,121.627451 107.987498,119.917587 98.9987851,116.772566 L99,0 L99,0 Z'
  ]
}

export const faShorts = {
  prefix: 'fas',
  iconName: 'shorts',
  icon: [
    '512',
    '512',
    [],
    'qxshorts',
    'M504.871,124 L576,460.680794 L324.39838,518 L288,331.733083 L250.875,518 L0,460.680794 L71.764,124 L504.871,124 Z M490.928592,58 L499.801,100 L76.88,100 L85.8322298,58 L490.928592,58 Z'
  ]
}

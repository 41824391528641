import { AuthStrategies, AuthError } from './types';
import { EnvProvider } from './../EnvProvider';
import { JWT_TOKEN_KEY } from '@/config/app';
import { decodeJwt } from 'jose';

/**
 * The AuthClient is responsible for authenticating the user,
 * handling token refresh, and browser storage interaction
 * of auth data.
 */

interface LoginFormData {
  email: string;
  password: string;
  personID?: string;
}

export async function createAuthClient() {

  // get available auth strategies on create
  let strategies: AuthStrategies[] = [];

  async function getStrategies(username?: string, personID?: string) {
    try {
      const response = await fetch(`${EnvProvider.endpoints.baseUrl}/strategies`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...(!!username ? {  username } : {}),
          ...(!!personID ? {  personID } : {}),
        })
      });

      const _strategiesObject = await response.json()

      if (response.status === 409) {
        return _strategiesObject;
      }

      if (response.status !== 200 && _strategiesObject.message) {
        throw _strategiesObject.message;
      }

      strategies = Object.keys(_strategiesObject).map((key) => {
        if (key === 'oauth2') {
          return `oauth:${_strategiesObject[key]}`;
        }
        return _strategiesObject[key] ? key as AuthStrategies : null;
      }).filter(v => !!v);

      return strategies;
    } catch (error) {
      throw error;
    }
  }

  async function login(form: LoginFormData) {

    if (!form.email || !form.password) {
      throw new Error('Email and password are required');
    }

    const useJwt = import.meta.env.QX_DEV_USE_JWT === 'true' || EnvProvider.jwt;

    const request = await fetch(`${EnvProvider.endpoints.baseUrl}/sign-in${useJwt ? '?jwt=true' : ''}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: form.email,
        password: form.password,
        ...(form.personID ? { personID: form.personID } : {}),
      })
    });

    if (useJwt) {
      const response = await request.json();
      if (!!response.error) {
        throw response.error;
      }

      const decoded = decodeJwt(response.token);
      localStorage.setItem(JWT_TOKEN_KEY, JSON.stringify({
        token: response.token,
        expires_at: new Date(decoded.exp! * 1000)
      }));
    }

    if (request.ok) {
      return true;
    }

    const response = await request.json();
    if (!!response.error) {
      console.error('Login error', response)
      throw response.error;
    }

    return false;
  }

  async function logout() {
    localStorage.removeItem(JWT_TOKEN_KEY);
  }

  async function createAccount(form: LoginFormData) {

    if (!form.email || !form.password) {
      throw new Error('Email and password are required');
    }

    const request = await fetch(`${EnvProvider.endpoints.baseUrl}/sign-up`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: form.email,
        password: form.password,
        ...(form.personID ? { personID: form.personID } : {}),
      })
    });

    if (request.ok && request.status === 200) {
      return true;
    }

    const response = await request.json();

    if (!!response.error) {
      if (response.error.includes('weak-password')) {
        throw AuthError.WeakPassword;
      }
      throw response.error;
    }

    return response.status === 200;
  }

  async function forgotPassword(email: string) {

    if (!email) {
      throw new Error('Email is required');
    }

    const request = await fetch(`${EnvProvider.endpoints.baseUrl}/forgot-password/firebase`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: email,
      })
    });

    if (request.ok) {
      return true
    }

    const response = await request.json();

    if (!!response.error) {
      throw response.error;
    }

    return false;
  }

  return {
    getStrategies,
    strategies,
    getToken() {
      if (EnvProvider?.strategies?.firebase?.jwt) {
        const val = localStorage.getItem(EnvProvider.strategies.firebase.jwt);
        return val ? JSON.parse(val)?.token : null;
      }
      return null;
    },
    login,
    logout,
    createAccount,
    forgotPassword,
  }
}

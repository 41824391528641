import UserAcceptedTermsAgreementMiddleware from './middleware/user-accepted-terms-agreement';
import ScopeRequiresTwoFactorMiddleware from './middleware/scope-requires-two-factor';
import UserIsAuthenticatedMiddleware from './middleware/user-is-authenticated';
import UserHasTwoFactorMiddleware from './middleware/user-has-two-factor';
import UserHasRoleMiddleware from './middleware/user-has-role';
import { CONFIG_ROUTES } from '@/config/routes';

// App Routes
import LiveRoutes from '@/views/live/routes';
import DoorRoutes from '@/views/doors/routes';
import TimelineRoutes from '@/views/timeline/routes';
import SearchRoutes from '@/views/search/routes';
import DeviceRoutes from '@/views/devices/routes';
import PlaceRoutes from '@/views/place/routes';
import PeopleRoutes from '@/views/person/routes';
import BadgeRoutes from '@/views/badge/routes';
import GroupsRoutes from '@/views/groups/routes';
import PermissionsRoutes from '@/views/permissions/routes';
import RolesRoutes from '@/views/roles/routes';
import GatewaysRoutes from '@/views/gateways/routes';
import CamerasRoutes from '@/views/cameras/routes';
import ViewsRoutes from '@/views/views/routes';
import ClipsRoutes from '@/views/saved-clips/routes';
import ClipsPublicRoutes from '@/views/saved-clips/routes-public';
import SchedulesRoutes from '@/views/schedules/routes';
import HolidaySetsRoutes from '@/views/holiday-sets/routes';
import ReportsRoutes from '@/views/reports/routes';
import AuditRoutes from '@/views/audit/routes';
import RulesRoutes from '@/views/rules/routes';
import HelpRoutes from '@/views/help/routes';
import IntegrationRoutes from '@/views/integrations/routes';
import SettingsRoutes from '@/views/settings/routes';

export default [
  /**
   * App Routes
   *  These routes requires the User to be logged in.
   */
   {
    path: CONFIG_ROUTES.APP_PATH_PREFIX,
    components: {
      default: () =>
        import('@/views/layouts/app/AppContainer.vue')
    },
    meta: {
      middleware: [
        // DEV NOTE :: the order of this middleware is important
        UserIsAuthenticatedMiddleware,
        UserHasRoleMiddleware,
        // we must accept terms before we can perform any custom mutations/queries
        UserAcceptedTermsAgreementMiddleware,
        ScopeRequiresTwoFactorMiddleware,
        UserHasTwoFactorMiddleware,
      ]
    },
    children: [
      ...LiveRoutes,
      ...DoorRoutes,
      ...TimelineRoutes,
      ...SearchRoutes,
      ...DeviceRoutes,
      ...PlaceRoutes,
      ...PeopleRoutes,
      ...BadgeRoutes,
      ...GroupsRoutes,
      ...PermissionsRoutes,
      ...RolesRoutes,
      ...GatewaysRoutes,
      ...CamerasRoutes,
      ...ViewsRoutes,
      ...ClipsRoutes,
      ...ClipsPublicRoutes,
      ...SchedulesRoutes,
      ...HolidaySetsRoutes,
      ...ReportsRoutes,
      ...AuditRoutes,
      ...RulesRoutes,
      ...HelpRoutes,
      ...IntegrationRoutes,
      ...SettingsRoutes,

      // An app-specific redirect, if the user doesn't type anything after the parent prefix
      {
        path: '/',
        redirect: { name: CONFIG_ROUTES.APP_HOME_REDIRECT }
      },
      {
        path: '',
        redirect: { name: CONFIG_ROUTES.APP_HOME_REDIRECT }
      },
      // An app-specific wildcard
      // {
      //   beforeEnter: (to, from, next) => {
      //     // console.info('### /#/qx/* - enter  app wildcard, take user to 404 page');
      //     next();
      //   },
      //   path: CONFIG_ROUTES.WILDCARD,
      //   name: CONFIG_ROUTES.APP_404,
      //   components: {
      //     default: () => import(/* webpackChunkName: "app-404" */ '@/views/errors/App404.vue')
      //   }
      // }
    ]
  },
]

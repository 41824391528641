import { hasPrivilege, hasPrivileges, hasRequiredPrivileges } from "@/utils/Privileges";
import type { AuthUser, AuthUserBaseFields, SetAuthUser } from "./../types";
import { provideApolloClient, useQuery } from '@vue/apollo-composable';
import { computed, reactive, ComputedRef } from 'vue';
import { qx_ePrivilege_enum } from "@/graphql/types";
import { ApolloClient } from '@/utils/ApolloClient';
import { ROOT_USER_DOMAIN } from '@/config/app';
import { gql } from '@apollo/client/core';

/**
 * The following keys will always
 * be available on the User.
 */
const defaultAuthUser: AuthUserBaseFields = {
  personID: '',
  avatar: '',
  suffix: '',
  salutation: '',
  firstName: '',
  lastName: '',
  email: '',
  // scope related role
  scopeRole: 'qx',
  // person related role
  roleID: '',
  privileges: [],
  placeID: '',
  path: '',
  place: {
    id: '',
    name: '',
    path: '',
  },
  // Any User w/ an email containing ROOT_USER_DOMAIN
  isRoot: false,
  // for Offline Login in Gateway Mode
  hasOfflinePassword: false,
  // middleware-esque values
  acceptedTerms: false,
  // account has 2fa enabled
  twoFactorSetup: false,
  // 2fa delivery method; conditional UI rendering
  twoFactorMethods: [],
  // account has passed 2fa verification
  twoFactorVerified: false,
  // comes from /me, informs the client if the User must have 2fa enabled
  twoFactorRequired: false,
  // functions are overwritten by the computed property
  // hasPrivilege: (p: qx_ePrivilege_enum) => false,
  // hasPrivileges: (p: qx_ePrivilege_enum[]) => false,
  // hasRequiredPrivileges: (p: qx_ePrivilege_enum[]) => false,
};

const AuthPersonFragment = gql`
  fragment AuthPersonFragment on qx_Person {
    id
    firstName
    lastName
    suffix
    salutation
    avatar_md
    placeID
    place {
      id
      name
      path
    }
    email
    acceptedTerms {
      email
      termsID
    }
    gatewayPassword {
      id
    }
    roleID
    role {
      id
      toPrivileges {
        privilege
      }
    }
  }
`;

const GQL_GET_AUTH_PERSON = gql`
  query GQL_GET_AUTH_PERSON($personID: String!) {
    people(where: { id: { _eq: $personID } }) {
      ...AuthPersonFragment
    }
  }
  ${AuthPersonFragment}
`;

const GQL_SUB_AUTH_PERSON = gql`
  subscription GQL_SUB_AUTH_PERSON($personID: String!) {
    people(where: { id: { _eq: $personID } }) {
      ...AuthPersonFragment
    }
  }
  ${AuthPersonFragment}
`;

export interface useUserReturn {
  isReady: ComputedRef<boolean>;
  data: ComputedRef<AuthUser>;
  retry: () => void;
};

/**
 * Returns a computed property containing the current user
 * (Auth, ApplicationUser, and Person records combined).
 */
export function useUser(): useUserReturn {

  /**
   * Determine if we have user data or not. It
   * is possible for _user to be populated
   * even though the user has logged out
   */
  const _user = reactive<AuthUserBaseFields>({...defaultAuthUser})

  // determines what ApplicationUser is returned
  const personID = computed(() => _user.personID);

  /**
   * maintain an up-to-date version of the Application User
   */
  const variables = computed(() => ({
    personID: personID.value
  }));

  /**
   * personID is required to get the ApplicationUser,
   * also check for 2 Factor Authentication
   */
  const isEnabled = computed(() => {
    const hasPersonID = !!variables.value.personID;
    const has2Fa = !!_user.twoFactorSetup;
    const isVerified = !!_user.twoFactorVerified;
    return hasPersonID && (has2Fa ? isVerified : true);
  });

  /**
   * The Auth Store is used in our router middleware. Because we use this
   * store in our middleware, it is considered to be "outside of setup"
   * and thus any calls to the ApolloClient from composables will
   * fail. We wrap the useQuery call in provideApolloClient to
   * ensure it always has access.
   */
  const { onResult, subscribeToMore, refetch, result } = provideApolloClient(ApolloClient)(() =>
    useQuery(
      GQL_GET_AUTH_PERSON,
      variables,
      computed(() => ({
        enabled: !!isEnabled.value,
      })),
    )
  );

  subscribeToMore(() => ({
    document: GQL_SUB_AUTH_PERSON,
    variables: variables.value,
  }));

  /**
   * Combined values from Authentication,
   * ApplicationUser, and Person records
   */
  const set: SetAuthUser = async function(data: AuthUserBaseFields) {
    // reset to defaults, if null
    if (data === null) {
      for (const key of Object.keys(_user)) {
        _user[key] = defaultAuthUser[key as keyof AuthUserBaseFields];
      }
    // update user with key:vals provided
    } else {
      for (const key of Object.keys(data)) {
        _user[key] = data[key as keyof AuthUserBaseFields]
      }
    }

    return;
  }

  onResult(result => {
    if (result.loading) return;

    const person = result?.data?.people?.[0];
    if (!person) return;

    Object.assign(_user, {
      privileges: person?.role?.toPrivileges?.map?.(p => p.privilege) || [],
      hasOfflinePassword: !!person.gatewayPassword?.id,
      roleID: person?.role?.id || '',
      acceptedTerms: !!person?.acceptedTerms?.termsID,
      personID: person?.id,
      path: person?.place?.path,
      place: person?.place,
      firstName: person?.firstName,
      lastName: person?.lastName,
      suffix: person?.suffix,
      salutation: person?.salutation,
      avatar: person?.avatar_md,
      placeID: person?.placeID,
    });
  });

  /**
   * Return the _user object and add in
   * additional values or custom
   * functions to be accessed.
   */
  const data = computed((): AuthUser => {
    return {
      ..._user,
      isRoot: _user.email?.includes?.(ROOT_USER_DOMAIN) || false,
      hasPrivilege: (priv: qx_ePrivilege_enum) => hasPrivilege(priv, _user.privileges),
      hasPrivileges: (privs: qx_ePrivilege_enum[]) => hasPrivileges(privs, _user.privileges),
      hasRequiredPrivileges: (privs: qx_ePrivilege_enum[]) => hasRequiredPrivileges(privs, _user.privileges),
      set,
    }
  });

  function retry() {
    refetch();
    subscribeToMore(() => ({
      document: GQL_SUB_AUTH_PERSON,
      variables: variables.value,
    }));
  }

  return {
    retry,
    isReady: computed(() => {
      const twoFaReady = !!_user.twoFactorSetup ? _user.twoFactorVerified : true;
      const hasPlaceID = !!data.value.placeID;
      const hasAcceptedTerms = !!data.value.acceptedTerms;
      const hasPersonID = !!data.value.personID;
      return hasPersonID && hasPlaceID && twoFaReady && hasAcceptedTerms;
    }),
    data
  }
}

import { provideApolloClient, useQuery, useSubscription } from '@vue/apollo-composable';
import { MeResponse, AuthUser, AuthUserBaseFields } from './../types';
import { computedAsync, createEventHook, useStorage } from "@vueuse/core";
import { ApolloClient } from "@/utils/ApolloClient";
import { EnvProvider } from "@/utils/EnvProvider";
import { ComputedRef, ref, computed } from 'vue';
import { AuthClient } from "@/utils/AuthClient";
import { gql } from "@apollo/client/core";

const GQL_SIGNOUT = gql`
  mutation GQL_SIGNOUT {
    signout {
      success
    }
  }
`;

const GQL_SIGNOUT_SESSION = gql`
  mutation GQL_SIGNOUT_SESSION($sid: String!) {
    signout(sessionID: $sid) {
      success
    }
  }
`;

const GQL_SIGNOUT_EVERYWHERE = gql`
  mutation GQL_SIGNOUT_EVERYWHERE {
    signoutEverywhere {
      success
    }
  }
`;

const GQL_SUB_PERSON_PROFILES = gql`
  subscription GQL_SUB_PERSON_PROFILES($email: String!) {
    qx_vProfiles(where: { email: { _eq: $email } }) {
      id
      avatar_md
      firstName
      lastName
      email
      placeID
      place {
        id
        name
        toHomePlaces {
          integratorPlaceID
          customerPlaceID
          homePlace {
            id
            name
            type
            path
          }
        }
      }
    }
  }
`;

const GQL_SWITCH_PROFILE = gql`
  mutation GQL_SWITCH_PROFILE($personID: String!) {
    switchProfile(personID: $personID) {
      success
    }
  }
`;

export function useAuthentication(user: ComputedRef<AuthUser>) {

  const eventHook = createEventHook<{
    type: string,
    value: boolean
  }>();

  // when calling `/me` we cache the value and use this to check if we need to make the `/me` call again
  const meResponseCached = useStorage<null | MeResponse>(
    'qx.auth.me',
    null,
    undefined,
    {
      serializer: {
        read: (v: any) => v ? JSON.parse(v) : null,
        write: (v: any) => JSON.stringify(v),
      },
    },
  );

  // we always want to force a `/me` call the first time we authenticate
  // afterward we can use the cached version if available
  const firstMeCall = ref(false);

  // keep track of `authenticate` attempts, no other logic is bound to this variable in this file
  const attempts = ref(0);

  // determines if the User is authenticated
  const isAuthenticated = ref(false);
  if (EnvProvider.jwt)  {
    isAuthenticated.value = !!AuthClient.getToken();
  }

  // refers to authenticate() function query call
  const isAuthenticating = ref(false);

  // refers to login/logout calls
  const isLoading = ref(false);

  // when a 409 occurs and we need to switch profiles
  const isSwitchingProfiles = ref(false);
  const switchToProfile = ref(null);

  /**
   * Similar to how we set the User, there are several
   * paths for how a User can be logged out. When
   * that happens we need to clean the state.
   */
  function clearUser() {
    meResponseCached.value = null;
    user.value.set(null);
    firstMeCall.value = false;
    isAuthenticated.value = false;
    isAuthenticating.value = false;
  }

  /**
   * Used to determine if authenticated, and if not will attempt
   * to authenticate. Not to be confused with `isAuthenticated`,
   * a non-synchronous value.
   */
  async function authenticate(force?: boolean): Promise<MeResponse | null> {
    // console.trace('authenticate', force)
    try {
      let isForced = !firstMeCall.value || (force ?? false);
      isAuthenticating.value = true;

      // if not forcing and we have a cached value, use the cached value
      if (meResponseCached.value && !isForced) {
        isAuthenticated.value = true;

        return {
          id: user.value?.personID,
          name: `${user.value?.firstName || ''} ${user.value?.lastName || ''}`.trim(),
          twoFactorSetup: user.value?.twoFactorSetup,
          path: user.value?.path || '',
          email: user.value?.email || '',
          acceptedTerms: user.value?.acceptedTerms || false,
          twoFactorRequired: user.value.twoFactorRequired || false,
          twoFactorVerified: user.value.twoFactorVerified || false,
          role: user.value?.scopeRole || '',
          roleID: user.value?.roleID || '',
          privileges: user.value?.privileges || []
        };
      }

      if (!firstMeCall.value) {
        firstMeCall.value = true;
      }

      // inc attempt count
      attempts.value++
      const response = await fetch(`${EnvProvider.endpoints.baseUrl}/me`, {
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          ...(EnvProvider.jwt && AuthClient.getToken() ? {
            Authorization: `Bearer ${AuthClient.getToken()}`
          } : {})
        },
      });

      // only clear the User on a 401 status
      if (response.status === 401) {
        clearUser();
        return null;
      }

      // handle a 409 and force a re-authenticate on the user
      if (response.status === 409) {
        const data = await response.json()
        isSwitchingProfiles.value = true;
        switchToProfile.value = data.profile;
        return meResponseCached.value;
      }

      meResponseCached.value = await response.json()

      // update the current user, will refetch all settings if email changes
      if (meResponseCached.value) {
        // transform `MeResponse` to `AuthUserBaseFields`
        const updatedUserObject: Partial<AuthUserBaseFields> = {
          personID: meResponseCached.value.id,
          path: meResponseCached.value?.path,
          placeID: meResponseCached.value?.path?.split('.')?.filter(p => !!p)?.pop() || '',
          scopeRole: meResponseCached.value.role,
          roleID: meResponseCached.value.roleID,
          email: meResponseCached.value.email,
          acceptedTerms: meResponseCached.value.acceptedTerms,
          twoFactorRequired: meResponseCached.value.twoFactorRequired || false,
          twoFactorSetup: meResponseCached.value.twoFactorSetup,
          twoFactorVerified: meResponseCached.value.twoFactorVerified,
          twoFactorMethods: meResponseCached.value.twoFactorMethods,
          privileges: meResponseCached.value?.privileges
        };
        // @ts-ignore
        await user.value.set(updatedUserObject)
        isAuthenticated.value = true;
      }

      // This should not occur...
      return meResponseCached.value;

    } catch (error) {
      console.error('Authentication error:', {error})
      // if an error occurred and we never cached a previous User value, assume the User was never authenticated
      if (!meResponseCached.value) {
        clearUser();
        return null;
      }
      return meResponseCached.value;

    } finally {
      isAuthenticating.value = false;
    }
  }

  const { result, loading } = provideApolloClient(ApolloClient)(() =>
    useSubscription(GQL_SUB_PERSON_PROFILES, () => ({
      email: user.value?.email
    }), () => ({
      enabled: !!user.value?.email,
      fetchPolicy: 'no-cache'
    }))
  );

  const profiles = computed(() => {
    if (result.value?.qx_vProfiles?.length && !loading.value) {
      return result.value.qx_vProfiles
    }
    return [];
  });

  async function switchProfile(personID: string) {
    isLoading.value = true;

    const response = await ApolloClient.mutate({
      mutation: GQL_SWITCH_PROFILE,
      variables: { personID }
    });

    if (!response.data.switchProfile.success) {
      throw new Error('Failed to switch profile');
    }

    meResponseCached.value = null;
    user.value.set(null);

    await authenticate(true);

    await eventHook.trigger({ type: 'switch-profile', value: true });

    isLoading.value = false;
    return true;
  }

  async function cancelProfileSwitch() {
    const useJwt = import.meta.env.QX_DEV_USE_JWT === 'true' || EnvProvider.jwt;
    const hasJwt = useJwt ? AuthClient.getToken() : null;

    const request = await fetch(`${EnvProvider.endpoints.baseUrl}/me?cancel=true`, {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        ...(hasJwt ? {
          'Authorization': `Bearer ${hasJwt}`
        } : {})
      },
    });

    if (request.ok) {
      isSwitchingProfiles.value = false;
      switchToProfile.value = null;
      authenticate(true);
    }

    return true;
  }

  async function logout() {
    try {
      // prevent spamming of logout
      if (isLoading.value || (!isAuthenticated.value && !user.value?.email)) {
        return;
      }

      isLoading.value = true;
      await ApolloClient.mutate({ mutation: GQL_SIGNOUT })
      await AuthClient.logout();
      clearUser();

    } catch (error) {
      throw error

    } finally {
      isLoading.value = false;
    }
  }

  const login = async (form?: { email: string, password: string, personID?: string }) => {
    isLoading.value = true
    try {
      if (form) {
        await AuthClient.login(form)
      }
      if (isSwitchingProfiles.value) {
        isSwitchingProfiles.value = false;
        switchToProfile.value = null;
      }
      return await authenticate(true)

    } catch (error) {
      throw error
    } finally {
      isLoading.value = false
    }
  }

  /**
   * Used by the login page to determine
   * what login capabilities exist.
   */
  // DEV NOTE :: why computedAsync for a one-time call?
  const loadingStrategies = ref(false)
  const strategies = computedAsync(
    async () => await AuthClient.getStrategies(),
    [],
    { lazy: true, evaluating: loadingStrategies }
  )

  /**
   * Provide a Session ID for single signout.
   * If no ID is provided all sessions will
   * be signed out.
   */
  async function signoutSession(id?: string) {
    try {
      const mutation = id ? GQL_SIGNOUT_SESSION : GQL_SIGNOUT_EVERYWHERE;
      const response = await ApolloClient.mutate({
        mutation,
        variables: id ? { sid: id } : {}
      })
      if (id) {
        return !!response.data?.signout?.success;
      }
      return !!response.data?.signoutEverywhere?.success;
    } catch (error) {
      throw error
    }
  }

  return {
    on: eventHook.on,
    attempts,
    authenticate,
    isAuthenticated,
    isAuthenticating,
    isLoading,
    login,
    logout,
    signoutSession,
    profiles,
    switchProfile,
    isSwitchingProfiles,
    switchToProfile,
    cancelProfileSwitch,
    strategies,
    loadingStrategies,
    createAccount: AuthClient.createAccount,
    forgotPassword: AuthClient.forgotPassword,
    getToken: AuthClient.getToken,
  }
}

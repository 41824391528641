import { useAppScopeButton } from "./composables/use-app-scope-button";
import { useAppScrollbar } from "./composables/use-app-scrollbar";
import { useAppElement } from "./composables/use-app-element";
import { useFullScreen } from "./composables/use-fullscreen";
import { useAppTheme } from "./composables/use-app-theme";
import { useViewport } from "./composables/use-viewport";
import { Breakpoints } from "@/config/breakpoints";
import { defineStore, storeToRefs } from "pinia";
import { useBreakpoints } from "@vueuse/core";
import { useDeviceStore } from "./../device";
import { useMagicKeys } from '@vueuse/core';
import { reactive, watchEffect } from "vue";

/**
 * Create a composable store that will allow for
 * management of Widgets, Layouts, Views,
 * Toolbar, and Sidebar in the Live UI.
 */
export const useUiStore = defineStore('ui', () => {

  const appToolbar = useAppElement();
  const theme = useAppTheme();
  const appNavigation = useAppElement();
  const breakpoints = useBreakpoints(Breakpoints)
  const viewport = reactive(useViewport());
  const scroll = useAppScrollbar();
  const fullscreen = reactive(useFullScreen());
  const scope = reactive(useAppScopeButton());

  /**
   * Global Keyboard Events
   */
  // TODO :: move this and the device store OUTSIDE of this file, creates circular dependency
  const { settings, network } = storeToRefs(useDeviceStore())
  const { backquote, controlLeft, altLeft, semicolon } = useMagicKeys()
  watchEffect(() => {
    // 3-finger salute to toggle Player HUD
    if (controlLeft.value && altLeft.value && semicolon.value) {
      settings.value.enablePlayerHud = !settings.value.enablePlayerHud
    }

    if (backquote.value) {
      appNavigation.toggleVisibility()
    }
  });

  return {
    theme,
    scroll,
    breakpoints,
    viewport,
    fullscreen,
    scope,

    // TODO :: rename, remove the `app` prefix
    appToolbar,
    appNavigation,

    // Device and Network Information
    settings,
    network,
  }
});

export type UseUiStoreReturn = ReturnType<typeof useUiStore>;

import { ApolloClient } from "@/utils/ApolloClient";
import { gql } from "@apollo/client/core";

export async function getPlacePath(placeID: string) {
  const response = await ApolloClient.query({
    query: gql`
      query GQL_GET_PLACE_PATH_BY_PLACE_ID($placeID: String!) {
        place(id: $placeID) {
          id
          name
          path
        }
      }
    `,
    variables: {
      placeID
    },
    fetchPolicy: 'cache-first'
  })
  return response?.data?.place?.path;
}

import { isSubscription, createApolloClientSubscriptionsLink } from './create-apollo-client-subscriptions';
import { ApolloClient, createHttpLink, ApolloLink, split, from, } from '@apollo/client/core';
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { createApolloClientCache } from './create-apollo-client-cache';
import { readOnlyModeLink } from './utilities/read-only-mode-link';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { CreateApolloClientOptions } from './types';
import { errorLink } from './utilities/error-link';
import { getOperationAST } from 'graphql';

export async function createApolloClient(options: CreateApolloClientOptions) {

  if (import.meta.env.DEV) {  // Adds messages only in a dev environment
    loadDevMessages();
    loadErrorMessages();
  }

//===========================================================================//
//==  Cache  ================================================================//
//===========================================================================//

  // Use a pre-configured cache, if not provided use the default
  const { cache } = await createApolloClientCache(options.cacheKey || 'default', options.cache)

//===========================================================================//
//==  HTTP Endpoints  =======================================================//
//===========================================================================//

  // The primary link for handling GraphQL requests
  const httpBaseApiLink = createHttpLink({
    credentials: 'include',
    uri: options.httpEndpoint
  });

  /**
   * At one point in time we attempted to use the Relay schema due to it having
   * cursor-based pagination. However, Hasura's implementation of the Relay
   * schema was not well conceived and created numerous complications.
   * Hasura's Relay schema would mask the record ID using a unique
   * hash algorithm. The developer would have to parse each
   * record ID back to its non-Relay schema ID.
   */
  const baseHttpRelayLink = createHttpLink({
    credentials: 'include',
    uri: options.httpEndpoint?.replace('v1/graphql', 'v1beta1/relay')
  });

  /**
   * Our Http Link needs to handle both Graphql and Relay schemas.
   * When a request is sent through the httpEndpoint, determine
   * if the operation is meant for the Relay schema. Assume
   * it is a Relay operation if the operation name
   * contains the word "_connection".
   */
  const httpLink = split(
    (operation) => {
      const operationAST = getOperationAST(operation.query, operation.operationName);
      const queryName = (operationAST?.selectionSet?.selections?.[0] as any)?.name?.value;
      const isPaginationQuery = queryName.includes('_connection');
      return isPaginationQuery;
    },
    baseHttpRelayLink as ApolloLink,
    httpBaseApiLink as ApolloLink
  );


//===========================================================================//
//==  Subscriptions  ========================================================//
//===========================================================================//

  const wsLink = createApolloClientSubscriptionsLink(options)

  /**
   * This `link` will be used in our Apollo Client config options.
   * It will be made up of several links. This is where we
   * initially create the `link` variable name instance.
   */
  let link = split(isSubscription, wsLink, httpLink);


//===========================================================================//
//==  Auth & Headers  =======================================================//
//===========================================================================//

  // HTTP Auth header injection
  const headersLink = setContext(async (_, { headers }) => {

    /**
     * The `addHeaders` function is our opportunity to apply
     * both Authorization and custom headers on requests.
     */
    const customHeaders = await options.addHeaders?.() || {};

    return {
      headers: {
        ...headers,
        ...customHeaders,
      }
    };
  });


//===========================================================================//
//==  Link Chain  ===========================================================//
//===========================================================================//

  // The order of our operation flow.
  link = from([
    readOnlyModeLink,
    headersLink,
    new RetryLink({
      attempts: {
        max: 3,
        retryIf: (error, _operation) => {
          return ![
            'GQL_SUB_HEARTBEAT',
            'GQL_UPDATE_CUSTOMER_PERMISSION',
            'GQL_CREATE_CUSTOMER_PERMISSION',
          ].includes(_operation.operationName)
        }
      }
    }),
    errorLink(options.errorHandler),
    link
  ])


//===========================================================================//
//==  Put it all together  ==================================================//
//===========================================================================//

  // Create the apollo client
  const apolloClient = new ApolloClient({
    link,
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
    devtools: {
      enabled: !import.meta.env.PROD,
      name: 'QxControl'
    }
  })

  return apolloClient
}

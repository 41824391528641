// https://github.com/enisdenjo/graphql-ws
// TODO :: https://app.shortcut.com/qumulex/story/28667/implement-graphql-ws-package-in-client
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient as createWsClient } from "graphql-ws";
import { getMainDefinition } from '@apollo/client/utilities';
import { Operation } from '@apollo/client/core';
import { split } from '@apollo/client/core';
import { CreateApolloClientOptions, AddHeadersMethod } from './types';
import { getOperationAST } from 'graphql';

/**
 * Used as the first argument in `split` to determine
 * if the operation is a subscription.
 */
export const isSubscription = ({ query }: Operation): boolean => {
  const definition = getMainDefinition(query);
  return (
    definition.kind === 'OperationDefinition' &&
    definition.operation === 'subscription'
  );
};

/**
 * Hard-coded remote schema for use with
 * the heartbeat subscription.
 */
const remoteSchema = {
  queries: [],
  mutations: [],
  subscriptions: ['heartbeat']
};

export const createApolloClientSubscriptionsLink = (options: CreateApolloClientOptions) => {

  const wsLink = createSubscriptionLink(options.wsEndpoint, options.addHeaders);
  const wsRemoteLink = createSubscriptionLink(options.wsRemoteSchemaEndpoint, options.addRemoteHeaders);


  // handle relay schema subscriptions
  const wsRelayUrl = options.wsEndpoint?.replace('v1/graphql', 'v1beta1/relay');
  const wsRelayLink = createSubscriptionLink(wsRelayUrl, options.addHeaders);

  /**
   * Determine if we should use the
   * Relay schema for subscription
   */
   const relaySplitLink = split(
    (operation) => {
      const operationAST = getOperationAST(operation.query, operation.operationName);
      const queryName = (operationAST?.selectionSet?.selections?.[0] as any)?.name?.value;
      const isRelay = queryName.includes('_connection');
      return isRelay;
    },
    wsRelayLink, // goes through hasura
    wsLink // goes through hasura
  );

  const dualWebSocketLink = split(
    // split based on subscription destination
    (operation) => {
      const operationAST = getOperationAST(operation.query, operation.operationName);
      const subsName = operationAST?.selectionSet?.selections?.[0]?.name?.value;
      const isRemoteSubscription = remoteSchema.subscriptions.indexOf(subsName) > -1;
      return isRemoteSubscription;
    },
    wsRemoteLink, // heartbeat, does not go through hasura
    relaySplitLink
  );

  return dualWebSocketLink
}

function createSubscriptionLink(endpoint: string, addHeaders: AddHeadersMethod) {

  const wsClient = createWsClient({
    url: endpoint,
    lazy: true,
    retryAttempts: 3,
    connectionParams: async () => {
      const headers = await addHeaders();

      // TODO :: change this to know of the operation type
      if ('Authorization' in headers) {
        return { headers: {
          ...headers,
          authToken: headers.Authorization,
        } };
      }

      return { headers };
    }
  })

  // Create the subscription websocket link
  const wsLink = new GraphQLWsLink(wsClient)

  return wsLink;
}


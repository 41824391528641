import type { FirebaseOptions } from "firebase/app";

export { FirebaseOptions };

export type { User } from "firebase/auth";

export interface UserReturn {
  id: string;
  email: string;
  // firebase specific
  avatar?: string,
}

export interface FirebaseStrategyConfig extends FirebaseOptions {
  url: string;
  jwt: string | false;
}

export interface LdapStrategyConfig {
  url: string;
}

export interface MagicEmailStrategyConfig {
  url: string;
}

export interface CreateAuthClientConfig {
  firebase?: FirebaseStrategyConfig;
  ldap?: LdapStrategyConfig;
  magicEmail?: MagicEmailStrategyConfig;
}

export enum AuthProvider {
  Google = 'Google',
}

export enum AuthStrategies {
  TOTP = "totp",
  User = "user",
  LDAP = "ldap",
  Firebase = "firebase",
  MagicEmail = "magic-email",
  Entra = "oauth:entra",
}

export interface AccountData {
  provider?: AuthProvider;
  password?: string;
  email?: string;
}

export enum AuthError {
  InvalidCredentials = 'InvalidCredentials',
  EmailNotVerified = 'EmailNotVerified',
  WeakPassword = 'WeakPassword',
  NotAuthorized = 'NotAuthorized',
  SignInWithProvider = 'SignInWithProvider',
  PersonNotFound = 'PersonNotFound',
  MissingPlace = 'MissingPlace',
  NetworkTimeout = 'NetworkTimeout',
  // Firebase...
  // InvalidPassword = 'InvalidPassword',
}

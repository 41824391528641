import { GATEWAY_DEFAULT_ICON } from '@/constants/gateway';
import { ConfigRouteObject } from '@/types';

export const SETTINGS_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', 'sliders'],
  UI_LABEL: 'Settings',
  ROUTE_PATH: 'settings',
  ROUTE_NAME: 'settings.index',
  ROUTE_PARAM: '',
  PRIVILEGES: []
};

// DEV NOTE :: we dont actually use the route path for devices since it is the default settings route
export const SETTINGS_PROFILE_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', 'user'],
  UI_LABEL: 'Your Profile',
  ROUTE_PATH: 'profile',
  ROUTE_NAME: 'settings.profile',
  ROUTE_PARAM: '',
  PRIVILEGES: []
};

export const SETTINGS_DEVICE_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', 'laptop-mobile'],
  UI_LABEL: 'Device',
  ROUTE_PATH: 'device',
  ROUTE_NAME: 'settings.device',
  ROUTE_PARAM: '',
  PRIVILEGES: []
};

export const SETTINGS_PERMISSIONS_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', 'shield-check'],
  UI_LABEL: 'Permissions',
  ROUTE_PATH: 'permissions',
  ROUTE_NAME: 'settings.permissions',
  ROUTE_PARAM: '',
  PRIVILEGES: []
};

export const SETTINGS_AUTH_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', 'fingerprint'],
  UI_LABEL: 'Authentication',
  ROUTE_PATH: 'authentication',
  ROUTE_NAME: 'settings.authentication',
  ROUTE_PARAM: '',
  PRIVILEGES: []
};

export const SETTINGS_OFFLINE_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', GATEWAY_DEFAULT_ICON],
  UI_LABEL: 'Offline Access',
  ROUTE_PATH: 'offline-access',
  ROUTE_NAME: 'settings.offline',
  ROUTE_PARAM: '',
  PRIVILEGES: []
};

export const SETTINGS_ADMIN_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'qx'],
  UI_LABEL: 'Administration',
  ROUTE_PATH: 'administration',
  ROUTE_NAME: 'settings.administration',
  ROUTE_PARAM: '',
  PRIVILEGES: []
};

export const SETTINGS_ADMIN_BUILD_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'qx'],
  UI_LABEL: 'Build',
  ROUTE_PATH: 'admin-build',
  ROUTE_NAME: 'settings.administration.build',
  ROUTE_PARAM: '',
  PRIVILEGES: []
};

export const SETTINGS_ADMIN_SEARCH_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'qx'],
  UI_LABEL: 'Build',
  ROUTE_PATH: 'admin-search',
  ROUTE_NAME: 'settings.administration.search',
  ROUTE_PARAM: '',
  PRIVILEGES: []
};

export const SETTINGS_ADMIN_ANALYTICS_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'qx'],
  UI_LABEL: 'Analytics',
  ROUTE_PATH: 'admin-analytics',
  ROUTE_NAME: 'settings.administration.analytics',
  ROUTE_PARAM: '',
  PRIVILEGES: []
};

export const SETTINGS_SYSTEM_CONFIGURATION_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'cog'],
  UI_LABEL: 'System Config',
  ROUTE_PATH: 'system-config',
  ROUTE_NAME: 'settings.system.config',
  ROUTE_PARAM: '',
  PRIVILEGES: []
};

/**
 * Helper function for formatting a date string into a human-readable format.
 *  Input: 2024-07-09T04:00:00.000Z
 *  Output: "Tuesday, July 9th, 2024"
 */
export function formatHumanReadableDate(dateString: string) {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = { weekday: 'long', month: 'long', year: 'numeric' };
  const formatter = new Intl.DateTimeFormat('en-US', options);
  const parts = formatter.formatToParts(date);

  const weekday = parts.find(part => part.type === 'weekday')?.value;
  const month = parts.find(part => part.type === 'month')?.value;
  const day = date.getDate();
  const year = parts.find(part => part.type === 'year')?.value;

  const ordinalSuffix = getOrdinalSuffix(day);

  return `${weekday}, ${month} ${day}${ordinalSuffix}, ${year}`;

  function getOrdinalSuffix(day: number) {
    if (day > 3 && day < 21) {
      return 'th'; // Exceptions for 11th, 12th, 13th, etc.
    }
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  }
}

/**
 * @function daysUntil calculate the number of days between two dates
 * @param {string} toDateISO end date to calculate for, in ISO format
 * @param {string | undefined} fromDateISO start date to calculate for, in ISO format. defaults to current day
 * @returns {number} number of days between dates
 */
export const daysUntil = (toDateISO: string, fromDateISO: string | undefined = undefined): number => {
  try {
    const toDate: Date = new Date(toDateISO);
    const fromDate: Date = fromDateISO ? new Date(fromDateISO) : new Date();
    const diff: number = (toDate.getTime() - fromDate.getTime()) / (1000 * 3600 * 24);
    return Math.round(diff);
  } catch (error) {
    console.error('Error calculating `daysUntil`:', error);
    throw error;
  }
};


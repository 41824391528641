import { faMediaProfiles, faMediaProfilesSlash, faStreamStatus, faStreamStatusSlash } from '@/icons/custom';
import { qx_eDeviceConnectionStatus_enum, qx_eStreamStatus_enum } from '@/graphql/types';
import { isString } from '@/functions/string';
import { isArray } from '@/functions/array';

export const CAMERA_DEFAULT_ICON = 'video';
export const CAMERA_DEFAULT_PTZ_ICON = 'arrows-to-circle';
export const CAMERA_STREAM_STATUS_DEFAULT_ICON = faStreamStatus.iconName;
export const CAMERA_MEDIA_PROFILES_DEFAULT_ICON = faMediaProfiles.iconName;
export const CAMERA_TIMELAPSE_ICON = 'stopwatch';

export enum CAMERA_DISABLED_BY {
  GATEWAY = 'gateway-offline',
  CAMERA_DISCONNECTED = 'camera-disconnected',
  CAMERA_DISABLED = 'camera-disabled'
}

export const CAMERA_DEVICE_CONNECTION_STATUS_ICON = {
  [CAMERA_DISABLED_BY.GATEWAY]: {
    color: 'grey',
    icon: 'video-slash',
    value: 'Gateway Disconnected'
  },
  [CAMERA_DISABLED_BY.CAMERA_DISABLED]: {
    color: 'grey',
    icon: 'video-slash',
    value: 'Disabled'
  },
  // On Longbeach, a new Enum Value
  // Update Stream Status to still work with this new Enum Value
  [qx_eDeviceConnectionStatus_enum.NoEvents]: {
    color: 'orange',
    icon: ['far', 'video'],
    value: 'No Event Recording'
  },
  [qx_eDeviceConnectionStatus_enum.AwaitingEvents]: {
    color: 'orange',
    icon: ['far', 'video'],
    value: 'Awaiting Events'
  },
  [qx_eDeviceConnectionStatus_enum.EventSubscriptionFailed]: {
    color: 'orange',
    icon: ['far', 'video'],
    value: 'Event Subscription Failed'
  },
  [qx_eDeviceConnectionStatus_enum.Connected]: {
    color: 'green',
    icon: 'video'
  },
  [qx_eDeviceConnectionStatus_enum.Connecting]: {
    color: 'grey',
    icon: 'video',
    mask: 'circle',
    transform: 'shrink-1 left-1',
    style: {
      transform: 'scale(1.1) !important'
    },
    subColor: 'blue',
    subIcon: 'spinner',
    subTransform: 'shrink-4',
    subClass: 'fa-pulse'
  },
  [qx_eDeviceConnectionStatus_enum.Disconnected]: {
    color: 'red',
    icon: 'video-slash'
  },
  [qx_eDeviceConnectionStatus_enum.InvalidCredentials]: {
    color: 'grey',
    icon: 'video',
    mask: 'triangle',
    transform: 'grow-2',
    opacity: '1',
    subColor: 'red',
    subIcon: 'triangle-exclamation',
    subTransform: 'shrink-2'
  },
  [qx_eDeviceConnectionStatus_enum.NotSupported]: {
    color: 'grey',
    icon: 'video',
    mask: 'triangle',
    transform: 'grow-2',
    opacity: '1',
    subColor: 'red',
    subIcon: 'xmark',
    subMask: 'triangle',
    subTransform: 'shrink-6 down-2',
    subStyle: {
      transform: 'scale(0.9) !important'
    }
  },
  [qx_eDeviceConnectionStatus_enum.Rebooting]: {
    color: 'grey',
    icon: 'video',
    mask: 'circle',
    transform: 'shrink-1 left-1',
    subColor: 'blue',
    subIcon: 'rotate-right',
    subTransform: 'shrink-4',
    subClass: 'fa-spin'
  },
  [qx_eDeviceConnectionStatus_enum.Unknown]: {
    color: 'grey',
    icon: 'video',
    mask: 'triangle',
    transform: 'grow-2',
    opacity: '1',
    subColor: 'orange',
    subIcon: 'question',
    subMask: 'triangle',
    subTransform: 'shrink-7 down-2',
    subStyle: {
      transform: 'scale(0.9) !important'
    }
  },
  [qx_eStreamStatus_enum.WriteFailed]: {
    color: 'grey',
    icon: CAMERA_STREAM_STATUS_DEFAULT_ICON,
    mask: 'triangle',
    transform: 'shrink-2',
    style: {
      transform: 'scale(1.2) !important'
    },
    subColor: 'red',
    subIcon: 'exclamation',
    subMask: 'triangle',
    subTransform: 'shrink-7 down-2',
    subStyle: {
      transform: 'scale(0.9) !important'
    }
  },
  [qx_eDeviceConnectionStatus_enum.Deleting]: {
    tooltip: 'Deleting...',
    color: 'danger',
    mask: 'video-slash',
    icon: 'circle',
    transform: 'shrink-6 right-6 up-4',
    subIcon: 'spinner',
    subTransform: 'shrink-8 right-6 up-4',
    subClass: 'fa-pulse',
    subColor: 'white'
  },
  [qx_eDeviceConnectionStatus_enum.DeleteOnCloudStorageExpire]: {
    tooltip: 'Device will be deleted when all video in cloud storage has expired.',
    color: 'danger',
    mask: 'video-slash',
    icon: 'triangle',
    transform: 'shrink-4 right-7 up-5',
    subIcon: 'triangle-exclamation',
    subColor: 'info',
    subTransform: 'shrink-8 right-6 up-4',
  }
};

export const CAMERA_MEDIA_PROFILES_STATUS_ICON = {
  [CAMERA_DISABLED_BY.GATEWAY]: {
    color: 'grey',
    icon: 'media-profiles-slash',
    value: 'Gateway Disconnected'
    // mask: 'triangle',
    // transform: 'shrink-3',
    // style: {
    //   transform: 'scale(1.4) !important'
    // },
    // subColor: 'red',
    // subIcon: 'xmark',
    // subMask: 'triangle',
    // subTransform: 'shrink-7 down-2',
    // subStyle: {
    //   transform: 'scale(0.9) !important'
    // },
  },
  [CAMERA_DISABLED_BY.CAMERA_DISCONNECTED]: {
    color: 'grey',
    icon: 'media-profiles-slash',
    value: 'Camera Disconnected'
  },
  [CAMERA_DISABLED_BY.CAMERA_DISABLED]: {
    color: 'grey',
    icon: 'media-profiles-slash',
    value: 'Camera Disabled'
  },
  default: {
    style: {
      transform: 'scale(1.4) !important'
    }
  },
  all: {
    color: 'green',
    icon: CAMERA_MEDIA_PROFILES_DEFAULT_ICON,
    value: 'All Connected'
  },
  some: {
    color: 'grey',
    icon: CAMERA_MEDIA_PROFILES_DEFAULT_ICON,
    mask: 'triangle',
    transform: 'shrink-3',
    style: {
      transform: 'scale(1.4) !important'
    },
    subColor: 'orange',
    subIcon: 'question',
    subMask: 'triangle',
    subTransform: 'shrink-7 down-2',
    subStyle: {
      transform: 'scale(0.9) !important'
    },
    value: 'Some Connected'
  },
  none: {
    color: 'red',
    icon: faMediaProfilesSlash.iconName,
    value: 'None Connected'
  },
  one: {
    color: 'blue',
    icon: CAMERA_MEDIA_PROFILES_DEFAULT_ICON,
    value: 'Needs Attention. Enable more profiles, only 1 enabled.'
  },
  error: {
    color: 'grey',
    icon: CAMERA_MEDIA_PROFILES_DEFAULT_ICON,
    value: 'None Enabled',
    mask: 'triangle',
    transform: 'shrink-3',
    style: {
      transform: 'scale(1.4) !important'
    },
    subColor: 'red',
    subIcon: 'xmark',
    subMask: 'triangle',
    subTransform: 'shrink-7 down-2',
    subStyle: {
      transform: 'scale(0.9) !important'
    }
  }
};

export const getCameraObjectActionStatusIcon = (status: string) => {
  switch (status) {
    case 'CountExceeded':
      return {
        tooltip: 'Count Exceeded',
        color: 'orange',
        iconStyle: 'fas',
        icon: 'ban'
      };
    case 'CrossLine':
      return {
        tooltip: 'Cross Line',
        color: 'blue',
        iconStyle: 'fas',
        icon: 'grip-lines-vertical'
      };
    case 'Enter':
      return {
        tooltip: 'Enter',
        color: 'green',
        iconStyle: 'fas',
        icon: 'arrow-right'
      };
    case 'Exit':
      return {
        tooltip: 'Exit',
        color: 'red',
        iconStyle: 'fas',
        icon: 'arrow-left'
      };
    case 'Face':
      return {
        tooltip: 'Face',
        color: 'yellow',
        iconStyle: 'fas',
        icon: 'grin'
      };
    case 'Intrusion':
      return {
        tooltip: 'Intrusion',
        color: 'red',
        iconStyle: 'fas',
        icon: 'exclamation'
      };
    case 'LeftObject':
      return {
        tooltip: 'Left Object',
        color: 'yellow',
        iconStyle: 'fas',
        icon: 'wallet'
      };
    case 'Loitering':
      return {
        tooltip: 'Loitering',
        color: 'orange',
        iconStyle: 'fas',
        icon: 'hourglass'
      };
    case 'Motion':
      return {
        tooltip: 'Motion',
        color: 'blue',
        iconStyle: 'fas',
        icon: 'running'
      };
    case 'Remove Object':
      return {
        tooltip: 'Remove Object',
        color: 'red',
        iconStyle: 'fas',
        icon: 'hand-holding'
      };
  }
};

export const getCameraMediaProfilesDefaultIcon = (status: Record<string, any>[] | string) => {
  if (isString(status) && Object.values(CAMERA_DISABLED_BY).includes(status))
    return CAMERA_MEDIA_PROFILES_STATUS_ICON[status];

  if (!isArray(status) || status === undefined || !status) return false;

  const enabled = status.filter((mp) => mp.streamEnabled);
  const enabledCount = enabled.length;
  const connected = enabled.filter((mp) => mp.streamStatus === qx_eStreamStatus_enum.Connected);
  const connectedCount = connected.length;

  // no profiles enabled
  if (!enabledCount) return { ...CAMERA_MEDIA_PROFILES_STATUS_ICON['error'], type: 'Error' };

  // profiles available, but none connected
  if (!connectedCount) return { ...CAMERA_MEDIA_PROFILES_STATUS_ICON['none'], type: 'None' };

  // only 1 profile enabled, undesirable status
  if (enabledCount === 1) return { ...CAMERA_MEDIA_PROFILES_STATUS_ICON['one'], type: 'One' };

  // all profiles connected
  if (enabledCount === connectedCount) return { ...CAMERA_MEDIA_PROFILES_STATUS_ICON['all'], type: 'All' };

  // some enabled are connected, but not all
  return { ...CAMERA_MEDIA_PROFILES_STATUS_ICON['some'], type: 'Some' };
};

export const CAMERA_STREAM_STATUS_ICON = {
  [qx_eStreamStatus_enum.Connected]: {
    color: 'green',
    icon: CAMERA_STREAM_STATUS_DEFAULT_ICON
  },
  [qx_eStreamStatus_enum.Connecting]: {
    color: 'grey',
    icon: CAMERA_STREAM_STATUS_DEFAULT_ICON,
    subColor: 'blue',
    subIcon: 'spinner-third',
    subTransform: 'grow-2',
    subClass: 'fa-pulse'
  },
  [qx_eStreamStatus_enum.Disconnected]: {
    color: 'red',
    icon: faStreamStatusSlash.iconName
  },
  [qx_eStreamStatus_enum.InvalidCredentials]: {
    color: 'grey',
    icon: CAMERA_STREAM_STATUS_DEFAULT_ICON,
    mask: 'triangle',
    transform: 'shrink-2',
    style: {
      transform: 'scale(1.2) !important'
    },
    subColor: 'orange',
    subIcon: 'exclamation',
    subMask: 'triangle',
    subTransform: 'shrink-7 down-2',
    subStyle: {
      transform: 'scale(0.9) !important'
    }
  },
  [qx_eStreamStatus_enum.NotSupported]: {
    color: 'grey',
    icon: CAMERA_STREAM_STATUS_DEFAULT_ICON,
    mask: 'triangle',
    transform: 'shrink-2',
    style: {
      transform: 'scale(1.2) !important'
    },
    subColor: 'red',
    subIcon: 'xmark',
    subMask: 'triangle',
    subTransform: 'shrink-6 down-2',
    subStyle: {
      transform: 'scale(0.9) !important'
    }
  },
  [qx_eStreamStatus_enum.Unknown]: {
    color: 'grey',
    icon: CAMERA_STREAM_STATUS_DEFAULT_ICON,
    mask: 'triangle',
    transform: 'shrink-2',
    style: {
      transform: 'scale(1.2) !important'
    },
    subColor: 'orange',
    subIcon: 'question',
    subMask: 'triangle',
    subTransform: 'shrink-7 down-2',
    subStyle: {
      transform: 'scale(0.9) !important'
    }
  },
  [qx_eStreamStatus_enum.WriteFailed]: {
    color: 'grey',
    icon: CAMERA_STREAM_STATUS_DEFAULT_ICON,
    mask: 'triangle',
    transform: 'shrink-2',
    style: {
      transform: 'scale(1.2) !important'
    },
    subColor: 'red',
    subIcon: 'exclamation',
    subMask: 'triangle',
    subTransform: 'shrink-7 down-2',
    subStyle: {
      transform: 'scale(0.9) !important'
    }
  }
};

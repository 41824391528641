<template>
  <FaIcon
    class="RuleStatusIcon__status"
    :class="{
      'RuleStatusIcon__status--firing': status === qx_eFlowStatus_enum.Firing,
      'RuleStatusIcon__status--error': status === qx_eFlowStatus_enum.Error
    }"
    :icon="['far', 'circle-dot']"
    v-tooltip="{
      triggers: ['click', 'hover'],


      content: tooltip
    }"
  />
</template>

<script lang="ts">
import { qx_eFlowStatus_enum } from '@/graphql/types';
import { mixinStatusProps } from '@/icons/mixins';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    ...mixinStatusProps,
  },
  data: () => ({
    qx_eFlowStatus_enum
  }),
  computed: {
    tooltip() {
      // according to Luke, if status is null that "means normal , migrations wont let me set defaults"
      return `Status: ${this.status || 'Normal'}`;
    }
  }
});
</script>

<style lang="scss" scoped>
.RuleStatusIcon__status {
  color: var(--color-success-400);

  &--firing {
    color: var(--color-primary-400);
    animation: flash 1.5s var(--animate-cb) infinite;

    @include keyframes(flash) {
      20%,
      80% {
        color: var(--color-primary-400);
      }
      50% {
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  &--error {
    color: var(--color-danger-400);
  }
}
</style>

import {
  SEARCH_ROUTE,
  SEARCH_MODULES_ROUTE,
  LEGACY_SEARCH_ROUTE,
  SEARCH_MODULE_LPR_ROUTE,
  SEARCH_MODULE_ANALYTICS_ROUTE,
  SEARCH_MODULE_AUDIO_ROUTE,
  SEARCH_MODULE_EVENTS_ROUTE,
} from '@/config/routes';

/**
 * DEV NOTE :: every search module must have a `provider` component defined, and it must use a single `<slot />` definition in the `<template>` block
 */
export default [
  /**
   * CONTAINER - All Rule Routes will pass through this container
   *  This container is responsible for handling any global Rule-related fetching.
   */

  {
    // Do not define `name`, we want to default to the "Analytics Module" route
    path: SEARCH_ROUTE.ROUTE_PATH,
    components: {
      default: () => import('./SearchContainer.vue')
    },
    meta: {
      privileges: SEARCH_ROUTE.PRIVILEGES
    },
    children: [

      /**
       * Modules exist within `SearchModulesContainer.vue`
       */
      {
        // Do not define `name`, we want to default to the "Analytics Module" route
        path: '',
        meta: {
          privileges: SEARCH_MODULES_ROUTE.PRIVILEGES,
        },
        components: {
          default: () => import('./modules/SearchModulesContainer.vue')
        },
        children: [

          /**
           * "Events" Search Module
           * ...default search route
           */
          {
            path: '',
            name: SEARCH_MODULE_EVENTS_ROUTE.ROUTE_NAME,
            meta: {
              privileges: SEARCH_MODULE_EVENTS_ROUTE.PRIVILEGES,
            },
            components: {
              provider: () => import('./modules/events/SearchModuleEventsWrapper.vue'),
              default: () => import('./modules/events/SearchModuleEventsPage.vue'),
              toolbar: () => import('./modules/events/SearchModuleEventsToolbar.vue'),
              filters: () => import('./modules/events/SearchModuleEventsFilters.vue')
            },
          },

          /**
           * "Analytics" Search Module
           */
          {
            path: SEARCH_MODULE_ANALYTICS_ROUTE.ROUTE_PATH,
            name: SEARCH_MODULE_ANALYTICS_ROUTE.ROUTE_NAME,
            meta: {
              privileges: SEARCH_MODULE_ANALYTICS_ROUTE.PRIVILEGES,
            },
            components: {
              provider: () => import('./modules/analytics/SearchModuleAnalyticsWrapper.vue'),
              default: () => import('./modules/analytics/SearchModuleAnalyticsPage.vue'),
              toolbar: () => import('./modules/analytics/SearchModuleAnalyticsToolbar.vue'),
              filters: () => import('./modules/analytics/SearchModuleAnalyticsFilters.vue')
            },
          },


          /**
           * "LPR" Search Module
           */
          {
            path: SEARCH_MODULE_LPR_ROUTE.ROUTE_PATH,
            name: SEARCH_MODULE_LPR_ROUTE.ROUTE_NAME,
            meta: {
              privileges: SEARCH_MODULE_LPR_ROUTE.PRIVILEGES,
            },
            components: {
              provider: () => import('./modules/license-plate-recognition/SearchModuleLicensePlateRecognitionWrapper.vue'),
              default: () => import('./modules/license-plate-recognition/SearchModuleLicensePlateRecognition.vue'),
            },
          },

          /**
           * "LPR" Search Module
           */
          {
            path: SEARCH_MODULE_AUDIO_ROUTE.ROUTE_PATH,
            name: SEARCH_MODULE_AUDIO_ROUTE.ROUTE_NAME,
            meta: {
              privileges: SEARCH_MODULE_AUDIO_ROUTE.PRIVILEGES,
            },
            components: {
              provider: () => import('./modules/audio-recognition/SearchModuleAudioRecognitionWrapper.vue'),
              default: () => import('./modules/audio-recognition/SearchModuleAudioRecognition.vue'),
            },
          },
        ]
      },

      /**
       * Legacy "Clip Search"
       */
      {
        path: LEGACY_SEARCH_ROUTE.ROUTE_PATH,
        name: LEGACY_SEARCH_ROUTE.ROUTE_NAME,
        meta: {
          privileges: LEGACY_SEARCH_ROUTE.PRIVILEGES
        },
        components: {
          default: () => import('@/views/clip-search/ClipSearchPage.vue')
        },
      }
    ]
  }
];

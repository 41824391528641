/**
 * This file is auto-loaded in `@/src/boot/create-and-mount-app.ts`.
 * Place plugins in here that are to be loaded globally.
 */
import type { App } from "vue";
import icons from './icons'
import unhead from './unhead'
import errorHandling from './error-handling'
import floatingVue from './floating-vue'
import googleAnalytics from './google-analytics';
import addPwaClassToHtmlElement from './add-pwa-class-to-html-element';
import validation from './validation/index';
import vueToasted from './vue-toasted';
import pinia from './pinia';
import portalVue from './portal-vue';

export const registerPlugins = async (app: App) => {

  // TODO :: write glob import to register plugins instead of manually importing
  const plugins = [
    pinia,
    icons,
    errorHandling,
    googleAnalytics,
    addPwaClassToHtmlElement,
    validation,
    vueToasted,
    unhead,
    portalVue,
    floatingVue,
  ];

  for (const registerPlugin of plugins) {
    try {
      await registerPlugin(app)
    } catch(error) {
      console.error(`Unable to register plugin`, { plugin: registerPlugin, error })
    }
  }
  return;
};

import IsRootUserMiddleware from '@/router/middleware/is-root-user';
import DevRoutes from '@/views/_dev/routes';
import {
  SETTINGS_SYSTEM_CONFIGURATION_ROUTE,
  SETTINGS_ADMIN_ANALYTICS_ROUTE,
  SETTINGS_ADMIN_SEARCH_ROUTE,
  SETTINGS_ADMIN_BUILD_ROUTE,
  SETTINGS_PERMISSIONS_ROUTE,
  SETTINGS_OFFLINE_ROUTE,
  SETTINGS_PROFILE_ROUTE,
  SETTINGS_DEVICE_ROUTE,
  SETTINGS_ADMIN_ROUTE,
  SETTINGS_AUTH_ROUTE,
  SETTINGS_ROUTE,
} from '@/config/routes/settings';
import { EnvProvider, Envs } from '@/utils/EnvProvider';

export default [
  {
    path: SETTINGS_ROUTE.ROUTE_PATH,
    components: {
      default: () => import('./SettingsContainer.vue'),
    },
    meta: {
      privileges: SETTINGS_ROUTE.PRIVILEGES
    },
    children: [
      {
        path: '',
        // path: SETTINGS_DEVICE_ROUTE.ROUTE_PATH,
        name: SETTINGS_ROUTE.ROUTE_NAME,
        components: {
          default: () => import('./SettingsDevicePage.vue'),
        },
        meta: {
          label: SETTINGS_DEVICE_ROUTE.UI_LABEL
        }
      },
      {
        path: SETTINGS_PROFILE_ROUTE.ROUTE_PATH,
        name: SETTINGS_PROFILE_ROUTE.ROUTE_NAME,
        components: {
          default: () => import('./SettingsProfilePage.vue'),
        },
        meta: {
          label: SETTINGS_PROFILE_ROUTE.UI_LABEL
        }
      },
      {
        path: SETTINGS_SYSTEM_CONFIGURATION_ROUTE.ROUTE_PATH,
        name: SETTINGS_SYSTEM_CONFIGURATION_ROUTE.ROUTE_NAME,
        components: {
          default: () => import('./SettingsSystemConfigurationPage.vue'),
        },
        meta: {
          label: SETTINGS_SYSTEM_CONFIGURATION_ROUTE.UI_LABEL
        }
      },
      {
        path: SETTINGS_PERMISSIONS_ROUTE.ROUTE_PATH,
        name: SETTINGS_PERMISSIONS_ROUTE.ROUTE_NAME,
        components: {
          default: () => import('./SettingsPermissionsPage.vue'),
        },
        meta: {
          label: SETTINGS_PERMISSIONS_ROUTE.UI_LABEL
        }
      },
      {
        path: SETTINGS_AUTH_ROUTE.ROUTE_PATH,
        name: SETTINGS_AUTH_ROUTE.ROUTE_NAME,
        components: {
          default: () => import('./SettingsAuthPage.vue'),
        },
        meta: {
          label: SETTINGS_AUTH_ROUTE.UI_LABEL,
          // used in SettingsContainer to make the page full width
          expand: true
        }
      },
      {
        beforeEnter: (to, from, next) => {
          if (EnvProvider.env !== Envs.Cloud) {
            next({ name: SETTINGS_ROUTE.ROUTE_NAME });
          } else {
            next();
          }
        },
        path: SETTINGS_OFFLINE_ROUTE.ROUTE_PATH,
        name: SETTINGS_OFFLINE_ROUTE.ROUTE_NAME,
        components: {
          default: () => import('./SettingsOfflinePage.vue'),
        },
        meta: {
          label: SETTINGS_OFFLINE_ROUTE.UI_LABEL,
        }
      },
      {
        path: SETTINGS_ADMIN_ROUTE.ROUTE_PATH,
        name: SETTINGS_ADMIN_ROUTE.ROUTE_NAME,
        components: {
          default: () => import('./SettingsAdminPage.vue'),
        },
        meta: {
          label: SETTINGS_ADMIN_ROUTE.UI_LABEL,
          middleware: [IsRootUserMiddleware],
        },
        children: [
          {
            path: '',
            // path: SETTINGS_ADMIN_BUILD_ROUTE.ROUTE_PATH,
            name: SETTINGS_ADMIN_BUILD_ROUTE.ROUTE_NAME,
            components: {
              default: () => import('@/views/_dev/CloudBuild.vue')
            },
            meta: {
              expand: true,
              privileges: []
            }
          },
          {
            path: SETTINGS_ADMIN_SEARCH_ROUTE.ROUTE_PATH,
            name: SETTINGS_ADMIN_SEARCH_ROUTE.ROUTE_NAME,
            components: {
              default: () => import('@/views/_dev/AdminSearch.vue')
            },
            meta: {
              expand: true,
              privileges: []
            }
          },
          {
            path: SETTINGS_ADMIN_ANALYTICS_ROUTE.ROUTE_PATH,
            name: SETTINGS_ADMIN_ANALYTICS_ROUTE.ROUTE_NAME,
            components: {
              default: () => import('@/views/_dev/AdminAnalytics.vue')
            },
            meta: {
              expand: true,
              privileges: []
            }
          },
        ]
      },
      ...DevRoutes
    ]
  }
];

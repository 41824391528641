import {
  GROUPS_ROUTE,
  GROUP_CREATE_ROUTE,
  GROUP_EDIT_ROUTE,
  GROUP_VIEW_ROUTE,
  GROUP_VIEW_INFO_ROUTE
} from '@/config/routes/groups';

export default [
  /**
   * CONTAINER - All Group Routes will pass through this container
   *  This container is responsible for handling any global Group-related fetching.
   */
  {
    path: GROUPS_ROUTE.ROUTE_PATH,
    components: {
      default: () => import(/* webpackChunkName: "container-group" */ './GroupContainer.vue')
    },
    meta: {
      privileges: GROUPS_ROUTE.PRIVILEGES
    },
    children: [
      /**
       * Create Group
       */
      {
        path: GROUP_CREATE_ROUTE.ROUTE_PATH,
        name: GROUP_CREATE_ROUTE.ROUTE_NAME,
        meta: {
          privileges: GROUP_CREATE_ROUTE.PRIVILEGES,
          scopeSelectorDisabled: true
        },
        components: {
          default: () => import(/* webpackChunkName: "page-group-create" */ './GroupCreatePage.vue')
        }
      },

      /**
       * List of Groups
       */
      {
        path: '',
        name: GROUPS_ROUTE.ROUTE_NAME,
        components: {
          default: () => import(/* webpackChunkName: "page-group-list" */ './GroupListPage.vue')
        }
      },

      /**
       * CONTAINER - View Group Routes will pass through this container
       *  Parent Route is a container to fetch the Group object
       *  Child routes (includes view page) will reference the Group object pulled in the container
       *  This container will hold some generic/global elements for all Group View Pages
       */
      {
        path: GROUP_VIEW_ROUTE.ROUTE_PATH,
        // name: GROUP_VIEW_ROUTE.ROUTE_NAME,
        meta: {
          privileges: GROUP_VIEW_ROUTE.PRIVILEGES,
          scopeSelectorDisabled: true
        },
        components: {
          default: () => import(/* webpackChunkName: "container-group-view" */ './GroupContainerView.vue')
        },
        children: [
          /**
           * View Group - Info
           */
          {
            path: GROUP_VIEW_INFO_ROUTE.ROUTE_PATH,
            name: GROUP_VIEW_INFO_ROUTE.ROUTE_NAME,
            meta: {
              privileges: GROUP_VIEW_INFO_ROUTE.PRIVILEGES
            },
            components: {
              default: () => import(/* webpackChunkName: "page-group-view-info" */ './GroupViewInfoPage.vue')
            }
          },

          /**
           * Edit Group
           */
          {
            path: GROUP_EDIT_ROUTE.ROUTE_PATH,
            name: GROUP_EDIT_ROUTE.ROUTE_NAME,
            meta: {
              privileges: GROUP_EDIT_ROUTE.PRIVILEGES,
            },
            components: {
              default: () => import(/* webpackChunkName: "page-group-edit" */ './GroupEditPage.vue')
            }
          }
        ]
      }
    ]
  }
];

import { qx_ePACSStatus_enum, qx_eDeviceConnectionStatus_enum } from '@/graphql/types';
import { FaIconComplex } from '@/types';

export const DEVICE_DEFAULT_ICON = 'sensor';
export const DEVICE_DEFAULT_DIO_ICON = 'arrows-turn-to-dots';
export const DEVICE_DEFAULT_INPUT_ICON = 'arrow-right-to-arc';
export const DEVICE_DEFAULT_OUTPUT_ICON = 'arrow-right-from-arc';

export const getDeviceStatusIcon = (
  gatewayConnected: boolean,
  connection: qx_eDeviceConnectionStatus_enum | string,
  pacs: qx_ePACSStatus_enum,
  pacsHardware: qx_ePACSStatus_enum
): FaIconComplex => {
  if (connection === qx_eDeviceConnectionStatus_enum.Deleting) {
    return {
      tooltip: 'Deleting...',
      color: 'danger',
      iconStyle: 'fas',
      icon: 'sensor-off',
      mask: 'circle',
      transform: 'shrink-6 right-6 up-4',
      subColor: 'white',
      subIcon: 'spinner',
      subTransform: 'shrink-8 right-6 up-4',
      subClass: 'fa-pulse'
    };
  }

  if (connection === qx_eDeviceConnectionStatus_enum.DeleteOnCloudStorageExpire) {
    return {
      tooltip: 'Device will be deleted when all video in cloud storage has expired.',
      color: 'danger',
      iconStyle: 'fas',
      icon: 'sensor-off',
      mask: 'triangle',
      transform: 'shrink-4 right-7 up-5',
      opacity: '1',
      subIcon: 'triangle-exclamation',
      subColor: 'info',
      subTransform: 'shrink-8 right-6 up-4',
    };
  }
  
  // Gateway Disconnected
  if (!gatewayConnected) {
    return {
      tooltip: 'Gateway Disconnected',
      color: 'grey',
      icon: 'sensor-off',
      iconStyle: 'fas',
    };
  }

  if (connection === qx_eDeviceConnectionStatus_enum.AwaitingEvents) {
    return {
      tooltip: 'Awaiting Events',
      color: 'orange',
      iconStyle: 'fas',
      icon: 'sensor'
    };
  }

  if (connection === qx_eDeviceConnectionStatus_enum.EventSubscriptionFailed) {
    return {
      tooltip: 'Event Subscription Failed',
      color: 'orange',
      iconStyle: 'fas',
      icon: 'sensor'
    };
  }

  if (connection === qx_eDeviceConnectionStatus_enum.Detached) {
    return {
      tooltip: 'Maintenance Mode',
      color: 'yellow',
      icon: 'sensor-off',
      iconStyle: 'fas',
    };
  }

  // Invalid Credentials
  if (connection === qx_eDeviceConnectionStatus_enum.Unknown) {
    return {
      tooltip: 'Unknown',
      color: 'grey',
      icon: 'sensor',
      mask: 'triangle',
      style: {
        transform: 'scale(1.05) !important'
      },
      iconStyle: 'fas',
      subColor: 'orange',
      subIcon: 'question',
      subMask: 'triangle',
      subStyle: {
        transform: 'scale(0.9) !important'
      },
      subTransform: 'shrink-7 down-2',
      transform: 'grow-2'
    };
  }

  // Invalid Credentials
  if (connection === qx_eDeviceConnectionStatus_enum.InvalidCredentials) {
    return {
      tooltip: 'Invalid Credentials',
      color: 'grey',
      iconStyle: 'fas',
      icon: 'sensor',
      mask: 'triangle',
      transform: 'grow-2',
      opacity: '1',
      subColor: 'red',
      subIcon: 'triangle-exclamation',
      subTransform: 'shrink-2'
    };
  }

  // Not Supported
  if (connection === qx_eDeviceConnectionStatus_enum.NotSupported) {
    return {
      tooltip: 'Not Supported',
      color: 'grey',
      icon: 'sensor',
      mask: 'triangle',
      style: {
        transform: 'scale(1.05) !important'
      },
      subColor: 'red',
      subIcon: 'xmark',
      subMask: 'triangle',
      subStyle: {
        transform: 'scale(0.9) !important'
      },
      subTransform: 'shrink-7 down-2',
      transform: 'grow-2'
    };
  }

  // Rebooting
  if (connection === qx_eDeviceConnectionStatus_enum.Rebooting) {
    return {
      tooltip: 'Rebooting',
      color: 'blue',
      iconStyle: 'fas',
      icon: 'sensor',
      mask: 'circle',
      transform: 'shrink-6 right-6 up-4',
      subColor: 'white',
      subIcon: 'spinner',
      subTransform: 'shrink-8 right-6 up-4',
      subClass: 'fa-pulse'
    };
  }

  // Connecting
  if (connection === qx_eDeviceConnectionStatus_enum.Connecting) {
    return {
      tooltip: 'Connecting',
      color: 'blue',
      iconStyle: 'fas',
      icon: 'sensor',
      mask: 'circle',
      transform: 'shrink-6 right-6 up-4',
      subColor: 'white',
      subIcon: 'spinner',
      subTransform: 'shrink-8 right-6 up-4',
      subClass: 'fa-pulse'
    };
  }

  // Disconnected
  // Device could show as "Disconnected" because we do not try to connect
  // if it has no enabled "children", e.g. doors, videoSources
  if (connection === 'disabled') {
    return {
      tooltip: 'Disabled',
      color: 'grey',
      iconStyle: 'fas',
      icon: 'sensor-off'
    };
  }

  if (connection === qx_eDeviceConnectionStatus_enum.Offline) {
    return {
      tooltip: qx_eDeviceConnectionStatus_enum.Offline,
      color: 'red',
      iconStyle: 'fas',
      icon: 'sensor-off'
    };
  }

  if (connection === qx_eDeviceConnectionStatus_enum.Disconnected) {
    return {
      tooltip: qx_eDeviceConnectionStatus_enum.Disconnected,
      color: 'red',
      iconStyle: 'fas',
      icon: 'sensor-off'
    };
  }

  // NoEvents (camera failed ONVIF pull point)
  if (connection === qx_eDeviceConnectionStatus_enum.NoEvents) {
    return {
      tooltip: 'No Event Recording',
      color: 'orange',
      iconStyle: 'fas',
      icon: 'sensor'
    };
  }

  /**
   * PACS Hardware Statuses take priority
   * over other statuses, aside from
   * device connection status.
   */

  // Updating Config
  if (pacsHardware === qx_ePACSStatus_enum.Updating) {
    return {
      tooltip: 'Configuring',
      color: 'grey',
      iconStyle: 'fas',
      icon: 'sensor',
      mask: 'triangle',
      transform: 'grow-2',
      opacity: '1',
      subColor: 'blue',
      subIcon: 'triangle-exclamation',
      subTransform: 'shrink-2'
    };
  }

  // Failed to Validate Configuration
  if (pacs === qx_ePACSStatus_enum.ValidateFailed) {
    return {
      tooltip: 'Validate Failed',
      color: 'grey',
      iconStyle: 'fas',
      icon: 'sensor',
      mask: 'shield',
      transform: 'grow-2',
      opacity: '1',
      subColor: 'red',
      subIcon: 'shield-exclamation',
      subTransform: 'shrink-2'
    };
  }

  // Needs Update
  if (pacsHardware === qx_ePACSStatus_enum.NeedsUpdate) {
    return {
      tooltip: 'Needs Configuration',
      color: 'grey',
      iconStyle: 'fas',
      icon: 'sensor',
      mask: 'triangle',
      transform: 'grow-2',
      opacity: '1',
      subIcon: 'triangle-exclamation',
      subColor: 'orange',
      subTransform: 'shrink-2'
    };
  }

  // Update Failed
  if (pacsHardware === qx_ePACSStatus_enum.UpdateFailed) {
    return {
      tooltip: 'Configuration Failed',
      color: 'grey',
      iconStyle: 'fas',
      icon: 'sensor',
      mask: 'triangle',
      transform: 'grow-2',
      opacity: '1',
      subColor: 'red',
      subIcon: 'triangle-exclamation',
      subTransform: 'shrink-2'
    };
  }

  /**
   * PACS Update Statuses
   */
  // Needs Update
  if (pacs === qx_ePACSStatus_enum.NeedsUpdate) {
    return {
      tooltip: 'Needs Update',
      color: 'grey',
      iconStyle: 'fas',
      icon: 'sensor',
      mask: 'triangle',
      transform: 'grow-2',
      opacity: '1',
      subIcon: 'triangle-exclamation',
      subColor: 'yellow',
      subTransform: 'shrink-2'
    };
  }

  // Update Failed
  if (pacs === qx_ePACSStatus_enum.UpdateFailed) {
    return {
      tooltip: 'Update Failed',
      color: 'grey',
      iconStyle: 'fas',
      icon: 'sensor',
      mask: 'triangle',
      transform: 'grow-2',
      opacity: '1',
      subColor: 'red',
      subIcon: 'triangle-exclamation',
      subTransform: 'shrink-2'
    };
  }

  // Updating Config
  if (pacs === qx_ePACSStatus_enum.Updating) {
    return {
      tooltip: 'Updating Config',
      color: 'grey',
      iconStyle: 'fas',
      icon: 'sensor',
      mask: 'triangle',
      transform: 'grow-2',
      opacity: '1',
      subColor: 'blue',
      subIcon: 'triangle-exclamation',
      subTransform: 'shrink-2'
    };
  }

  /**
   * Generic statuses to show when no other
   * high priority statuses are visible.
   */

  // Connected
  if (connection === qx_eDeviceConnectionStatus_enum.Connected) {
    return {
      tooltip: qx_eDeviceConnectionStatus_enum.Connected,
      color: 'green',
      iconStyle: 'fas',
      icon: 'sensor'
    };
  }

  // Online
  if (connection === qx_eDeviceConnectionStatus_enum.Online) {
    return {
      tooltip: qx_eDeviceConnectionStatus_enum.Online,
      color: 'green',
      iconStyle: 'fas',
      icon: 'sensor'
    };
  }

  // TODO :: Updating Cards
  // if (pacs === qx_ePACSStatus_enum.) {}

  // TODO :: Updating Firmware
  // if (pacs === qx_ePACSStatus_enum.) {}

  return {
    tooltip: false,
    color: 'grey',
    iconStyle: 'fas',
    icon: 'sensor'
    // mask: 'triangle',
    // transform: 'grow-2',
    // opacity: '1',
    // subIcon: 'triangle-exclamation',
    // subColor: 'grey',
    // subTransform: 'shrink-2'
  };
};
